<script setup lang="ts">
import type { IAcceptanceDialogOwner } from '~/layers/settlement/types/acceptance';
import dateTimeHelper from '@frontend/ui-kit/src/utils/dateTimeHelper';
import Accordion from '@frontend/ui-kit/src/components/Base/Accordion.vue';
import { useAcceptanceDialog } from '~/layers/settlement/composables/useAcceptanceDialog';

interface Props {
    owners: IAcceptanceDialogOwner[];
}

withDefaults(defineProps<Props>(), {});

const { t } = useI18n();

const { getFileExtension, formatPassportSeries, getFileContentUrl } = useAcceptanceDialog();

const accordionContent = (item: IAcceptanceDialogOwner) => {
    return [
        {
            title: t('settlement.admin.acceptances.dialog_owner.passportSeries'),
            text: item.passportSeries && item.passportNumber 
                ? `${formatPassportSeries(item.passportSeries)} ${item.passportNumber}` 
                : '—',
        },
        {
            title: t('settlement.admin.acceptances.dialog_owner.passportIssued'),
            text: item.passportIssued ? item.passportIssued : '—',
        },
        {
            title: t('settlement.admin.acceptances.dialog_owner.passportDate'),
            text: item.passportDate ? dateTimeHelper.toDateString(new Date(item.passportDate)) : '—',
        },
        {
            title: t('settlement.admin.acceptances.dialog_owner.passportCode'),
            text: item.passportCode ? item.passportCode : '—',
        },
        {
            title: t('settlement.admin.acceptances.dialog_owner.registrationAddress'),
            text: item.registrationAddress ? item.registrationAddress :  '—',
        },
    ]
};
</script>

<template>
    <Accordion 
        v-if="owners && owners.length"
        :items="owners" 
        :arrowTop="true"
    >
        <template v-slot:title="{ item }">
            <div class="accordion-title">
                <div class="name">{{ item.lastName }} {{ item.firstName }} {{ item.patronymicName }}</div>
                <div class="icons">
                    <i v-if="item.isByPowerOfAttorney" class="ri-user-line title-isByPowerOfAttorney"></i>
                    <i v-if="false" class="ri-file-copy-line title-copy"></i>
                </div>
            </div>
            <div class="passport">{{ formatPassportSeries(item.passportSeries) }} {{ item.passportNumber }}</div>
        </template>
        <template v-slot:text="{ item }">
            <div class="accordion-content">
                <div 
                    v-for="block in accordionContent(item)"
                    :key="block.title"
                    class="accordion-content__block"
                >
                    <div class="content-title">{{ block.title }}</div>
                    <div class="content-text">{{ block.text }}</div>
                </div>
                <div v-if="item.files" class="accordion-content__block">
                    <div class="downloads">
                        <v-btn 
                            v-for="file in item.files"
                            :key="file.fileName"
                            class="downloads-item"
                            :href="getFileContentUrl(file.fileGuid)"
                            download
                            target="_blank"
                            variant="text"
                        >
                            <div class="downloads-item__type">
                                <div>{{ getFileExtension(file.fileName) }}</div>
                            </div>
                            <div class="downloads-item__name">{{ file.fileName }}</div>
                        </v-btn>
                    </div>
                </div>
            </div>
        </template>
    </Accordion>
</template>

<style scoped lang="scss">
.accordion {
    &-title {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icons {
            > i {
                font-size: 20px;
            }
            
            .title-isByPowerOfAttorney {
                margin-right: 12px;
                color: $color-accent02;
            }
    
            .title-copy {
                margin-right: 12px;
            }
        }
        
        .name {
            font-size: 16px;
            line-height: 125%;
            letter-spacing: -0.16px;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__block {
            padding: 8px 0;

            .content-title {
                color: $color-neutral03;
            }

            .content-text {
                margin-top: 4px;
            }
        }
    }
}

.passport {
    margin-top: 4px;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.16px;
    color: $color-neutral03;
}

.downloads {
    padding: 16px;
    border: 1px dashed $color-neutral02;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $color-accent02;
        padding: 0;

        &__type {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            background-color: $color-neutral01;
            font-size: 11px;
            font-family: $ff-bold;
            line-height: 125%;
            letter-spacing: -0.11px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;

            > div {
                display: block;
            }
        }

        &__name {
            padding-left: 12px;
            font-size: 14px;
            line-height: 125%; 
            letter-spacing: -0.14px;
        }
    }
}
</style>